<!-- 变更 changeUseLimitView 回显 -->
<template>
  <!-- <w-dialog v-if="dialogVisible" title="改使用限制" closeFree width="1000px" v-model="dialogVisible" okText="提 交" @cancel="PopupDialogClose" @ok="DetermineClick"> -->
  <el-dialog
    append-to-body
    title="使用限制"
    custom-class="dialogs"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1100px"
    @close="PopupDialogClose"
  >
    <div style="margin-top: -15px;">
      <!-- <p style="color:#666666;font-size: 13px;margin-top: 0px;padding-bottom: 10px;">入库单ID：M000300</p> -->
      <el-form ref="forms" v-loading="listLoading" :disabled="formDisabled" size="small" label-width="120px" :model="forms">
        <!-- 节假日可多选，点击“添加详细禁用时间”则显示下方配置时间段的控件 -->
        <el-form-item label="使用限制：">
          <el-checkbox v-model="rowData.isHolidayLimit" :true-label="1" :false-label="0" style="float: left;">节假日禁用</el-checkbox>
          <el-select v-model="holidayLimit" multiple :disabled="rowData.isHolidayLimit==0" size="small" style="width:350px;float: left;margin-left: 16px;" placeholder="请选择节假日">
            <el-option v-for="(item,index) in holidayList" :key="item.holiday" :label="item.holidayCn" :value="item.holiday">{{ item.holidayCn }}</el-option>
          </el-select>
          <!-- 禁用的时间段可添加多条 -->
          <el-button size="small" v-if="rowData.isHolidayLimit == 0" type="text" style="margin-left: 16px;color: #cccccc;">添加详细禁用时间</el-button>
          <el-button size="small" v-if="rowData.isHolidayLimit == 1" type="text" style="margin-left: 16px;" @click="addHolidayLimit">添加详细禁用时间</el-button>
          <!-- <el-button size="small" type="text" style="margin-left: 16px;">添加详细禁用时间</el-button> -->

          <!-- <p v-for="(itm,i) in holidayTimeList" :key="i" style="margin-left: 110px;display: flex;align-items: center;">
            <el-time-picker
              v-model="itm.value"
              size="small"
              is-range
              arrow-control
              :disabled="rowData.isHolidayLimit==0"
              value-format="HH:mm"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
            />
            <img v-if="i==(holidayTimeList.length-1) && rowData.isHolidayLimit == 1 && formDisabled == false" src="../assets/add.svg" alt="" style="width: 23px;height: 23px;margin-left: 12px;" @click="addHolidayLimit">
            <img v-if="i!=(holidayTimeList.length-1) && rowData.isHolidayLimit == 1 && formDisabled == false" src="../assets/delete.svg"  @click="deleteHolidayLimit(i)" alt="" style="width: 23px;height: 23px;margin-left: 12px;" /> -->
            <!-- <img src="../assets/add.svg" alt="" style="width: 23px;height: 23px;margin-left: 12px;" @click="addHolidayLimit"> -->
          <!-- </p> -->
        </el-form-item>
        <el-form-item style="margin-top:-15px;">
          <div v-for="(itm,i) in holidayTimeList" :key="i" style="margin-left: 110px;display: flex;align-items: center;">
            <el-time-picker
              v-model="itm.value"
              size="small"
              is-range
              arrow-control
              :disabled="rowData.isHolidayLimit==0"
              value-format="HH:mm"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
            />
            <img v-if="i==(holidayTimeList.length-1) && rowData.isHolidayLimit == 1 && formDisabled == false" src="../../assets/add.svg" alt="" style="width: 23px;height: 23px;margin-left: 12px;" @click="addHolidayLimit">
            <img src="../../assets/delete.svg" v-if="!formDisabled"  @click="deleteHolidayLimit(i)" alt="" style="width: 23px;height: 23px;margin-left: 12px;" />
          </div>
        </el-form-item>
        <el-form-item label="">
          <!-- <el-checkbox-group style="float: left;"> -->
          <el-checkbox v-model="rowData.isWeekLimit" :true-label="1" :false-label="0">周禁用</el-checkbox>
          <el-checkbox v-for="item in methodArray1" :key="item.weekCode" v-model="weekLimitChecked" :disabled="rowData.isWeekLimit==0" :label="item.weekCode">{{ item.weekName }}</el-checkbox>
          <!-- </el-checkbox-group> -->
          <!-- 禁用的时间段可添加多条 -->
          <el-button size="small" v-if="rowData.isWeekLimit == 0" type="text" style="margin-left: 66px;float: right;color: #cccccc;">添加详细禁用时间</el-button>
          <el-button size="small" v-if="rowData.isWeekLimit == 1" type="text" style="margin-left: 16px;float: right;" @click="addWeekLimit">添加详细禁用时间</el-button>
          <!-- <el-button size="small" type="text" style="margin-right: 66px;float: right;">添加详细禁用时间</el-button> -->
        </el-form-item>
        <el-form-item label="" style="margin-top:-29px">
          <p v-for="(itm,i) in weekTimeList" :key="i" style="margin-left: 110px;display: flex;align-items: center;">
            <el-time-picker
              v-model="itm.value"
              :disabled="rowData.isWeekLimit==0"
              size="small"
              is-range
              arrow-control
              value-format="HH:mm"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
            />
            <img v-if="i==(weekTimeList.length-1) && rowData.isWeekLimit == 1 && formDisabled == false" src="../../assets/add.svg" alt="" style="width: 23px;height: 23px;margin-left: 12px;" @click="addWeekLimit"/>
            <img  src="../../assets/delete.svg" v-if="!formDisabled"  @click="deleteWeekLimit(i)" alt="" style="width: 23px;height: 23px;margin-left: 12px;" />
            <!-- <img src="../assets/add.svg" alt="" style="width: 23px;height: 23px;margin-left: 12px;" @click="addWeekLimit"> -->
          </p>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox v-model="rowData.isDayLimit" :true-label="1" :false-label="0" style="width:150px;position:relative;display: block">日禁用</el-checkbox>
          <div v-for="(it,i) in dayDateList" :key="i" style="margin: -30px 43px 42px 103px;display: flex;align-items: center;float:left;">
            <el-date-picker
              v-model="it.value"
              size="small"
              :disabled="rowData.isDayLimit==0"
              value-format="yyyy-MM-dd"
              type="daterange"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
            <img v-if="i==(dayDateList.length-1) && rowData.isDayLimit==1 && formDisabled == false" src="../../assets/add.svg" alt="" style="width: 23px;height: 23px;margin-left: 12px;position:relative;" @click="addDayDateLimit"/>
            <img  src="../../assets/delete.svg" v-if="(dayDateList.length>1&&i==(dayDateList.length-1)) && rowData.isDayLimit==1 && formDisabled == false"  @click="deleteDayDateLimit(i)" alt="" style="width: 23px;height: 23px;margin-left: 12px;position:relative;" />
            <!-- <img src="../assets/add.svg" alt="" style="width: 23px;height: 23px;margin-left: 12px;position:relative;" @click="addDayDateLimit"> -->
          </div>
          <el-button size="small" v-if="rowData.isDayLimit == 0" type="text" style="margin-left: 30px;float: left;margin-top: -28px;position: relative;color: #cccccc;">添加详细禁用时间</el-button>
          <el-button size="small" v-if="rowData.isDayLimit == 1" type="text" style="margin-left: 30px;float: left;margin-top: -28px;position: relative;" @click="addDayLimit">添加详细禁用时间</el-button>
          <!-- <el-button size="small" type="text" style="margin-left: 30px;float: left;margin-top: -28px;position: relative;" @click="addDayLimit">添加详细禁用时间</el-button> -->
          <div style="margin-left: 100px;margin-top: 0px;clear: both;">
            <p v-for="(itm,i) in dayTimeList" :key="i" style="display: flex;align-items: center;">
              <el-time-picker
                v-model="itm.value"
                :disabled="rowData.isDayLimit==0"
                size="small"
                is-range
                arrow-control
                value-format="HH:mm"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
              />
              <img v-if="i==(dayTimeList.length-1) && rowData.isDayLimit==1 && formDisabled == false" src="../../assets/add.svg" alt="" style="width: 23px;height: 23px;margin-left: 12px;" @click="addDayLimit">
              <img  src="../../assets/delete.svg" v-if="!formDisabled"  @click="deleteDayLimit(i)" alt="" style="width: 23px;height: 23px;margin-left: 12px;" >
              <!-- <img src="../assets/add.svg" alt="" style="width: 23px;height: 23px;margin-left: 12px;" @click="addDayLimit"> -->
            </p>
          </div>
        </el-form-item>
        <el-form-item label="礼券内容：">
          <!--
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
                @change="onEditorChange($event)" -->
          <!-- <quill-editor
            ref="myQuillEditor"
            v-model="rowData.tktDetails"
            :disabled="formDisabled"
            :options="editorOption"
          /> -->
          <el-input v-model="rowData.tktDetails" placeholder="请输入礼券内容" type="textarea" rows="5" />
        </el-form-item>
        <el-form-item label="使用须知：">
          <!-- <quill-editor
            ref="myQuillEditor"
            v-model="rowData.tktNotice"
            :disabled="formDisabled"
            :options="editorOption"
          /> -->
          <el-input v-model="rowData.tktNotice" placeholder="请输入使用须知" type="textarea" rows="5" />
        </el-form-item>
      </el-form>
    </div>
    <!-- <div slot="footer" class="dialog-footer">
          <el-button size="small" type="primary">保存</el-button>
          <el-button size="small" @click="changeUseRule = false">取消</el-button>
        </div>
      </el-dialog> -->
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="PopupDialogClose">取消</el-button>
      <el-button v-if="formDisabled == false" size="small" type="primary" @click="DetermineClick">保存</el-button>
    </div>
  </el-dialog>
  <!-- </w-dialog> -->
</template>
<script>
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// import { quillEditor } from 'vue-quill-editor'
import { getHoliday } from '@/api/process'
export default {
  // components: { quillEditor },
  props: {
    PopupDialogFlag: {
      default: false
    },
    tableData: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      rowData: {
        tktNotice: '',
        tktDetails: ''
      },
      formDisabled: false,
      holidayList: [], // 节假日列表
      holidayLimit: [], // 选择的节假日id集合，遍历出日期 回显的时候拆分this.rowData.holidayLimit拿到id集合
      // holidayTimeList: [{
      //   value: ['00:00', '23:59']
      // }], // 节假日禁用时间
      holidayTimeList: [], // 节假日禁用时间
      methodArray1: [
        { weekCode: 1, weekName: '星期一' },
        { weekCode: 2, weekName: '星期二' },
        { weekCode: 3, weekName: '星期三' },
        { weekCode: 4, weekName: '星期四' },
        { weekCode: 5, weekName: '星期五' },
        { weekCode: 6, weekName: '星期六' },
        { weekCode: 7, weekName: '星期日' }
      ],
      // weekTimeList: [{
      //   value: ['00:00', '23:59']
      // }], // 周禁用时间
      weekTimeList: [], // 周禁用时间
      weekLimitChecked: [], // 被选中的禁用周 星期1-6
      dayDateList: [{
        value: ''
      }], // 日禁用-日期
      dayTimeList: [], // 日禁用-时间
      // dayTimeList: [{
      //   value: ''
      // }], // 日禁用-时间
      department_leader_options: [],
      // checkedMethodArray: ['周禁用'],
      // content: '', // 富文本编辑器里面的内容
      editorOption: {},
      currentLength: 0,
      status: 1,
      // createTime: ['22:20:00', '00:00:00'],
      // createTime: [{'beginTime':'08:20:00', 'endTime':'09:20:00'},{'beginTime':'08:10:00', 'endTime':'09:10:00'}],
      value0: false,
      listLoading: false,
      dialogVisible: false,
      pagetype: 'add', //
      forms: {
        postId: '',
        postName: '',
        remark: ''
      },
      rules: {
        postName: [
          { required: true, message: '请输入礼券内容', trigger: 'blur' }
        ],
        postName1: [
          { required: true, message: '请输入使用须知', trigger: 'blur' }
        ]
      }
    }
  },

  computed: {},
  watch: {
    PopupDialogFlag() {
      this.dialogVisible = this.PopupDialogFlag
    }
  },
  created() {
  },
  beforeDestroy() {
  },
  methods: {
    // 删除
    deleteHolidayLimit(index) {
      this.holidayTimeList.splice(index, 1);
    },
    // 删除
    deleteWeekLimit(index) {
      this.weekTimeList.splice(index, 1);
    },
    // 删除
    deleteDayDateLimit(index) {
      this.dayDateList.splice(index, 1);
    },
    // 删除
    deleteDayLimit(index) {
      this.dayTimeList.splice(index, 1);
    },
    opening(options) {
      // console.log(options)
      this.rowData = options.row
      let tktNotice = options.row.tktNotice?options.row.tktNotice:''
      let tktDetails = options.row.tktDetails?options.row.tktDetails:''
      this.$set(this.rowData,'tktNotice',tktNotice)
      this.$set(this.rowData,'tktDetails',tktDetails)
      // this.rowData.tktNotice = options.row.tktNotice?options.row.tktNotice:''
      // this.rowData.tktDetails = options.row.tktDetails?options.row.tktDetails:''
      if (options.type) {
        if (options.type == 2) {
          // 查看
          // console.log("##")
          this.formDisabled = true
        }
        else{
          this.formDisabled = false
        }
      }
      // console.log(this.tableData)
      const that = this
      this.dialogVisible = true
      getHoliday().then(res => {
        // console.log(res)
        this.holidayList = res.data
      })

      // console.log(this.rowData)
      // 回显处理数据
      // 刚注释
      // this.holidayList = this.rowData.holidayListDatas?this.rowData.holidayListDatas:[]
      // console.log(this.rowData.holidayLimit)
      // 节假日 回显的id
      let holidayLimitIds = []

      // console.log(typeof this.rowData.holidayLimit!='string')
      // 节假日详细禁用时间
      let holidayTimeList = [] // [{ value: ['00:00', '23:59'] }]
      let hArr = []
      if (this.rowData.holidayLimit) {
        holidayTimeList = []
        hArr = []
        if (typeof this.rowData.holidayLimit != 'string') {
          hArr = this.rowData.holidayLimit
          // console.log("!!!")
        } else {
          // console.log(":::")
          if (typeof JSON.parse(this.rowData.holidayLimit) != 'string') {
            hArr = JSON.parse(this.rowData.holidayLimit)
          } else {
            hArr = JSON.parse(JSON.parse(this.rowData.holidayLimit))
          }
        }
        // console.log(hArr,typeof hArr,"this.rowData.holidayLimit")
        if (hArr.length != 0) {
          hArr.forEach(e => {
            // console.log(e)
            if (holidayLimitIds.indexOf(e.holiday) == -1) {
              holidayLimitIds.push(e.holiday) // 节假日 回显的id
            }
          })
          hArr[0].timeList.forEach(itm => {
            holidayTimeList.push({
              value: [itm.beginTime, itm.endTime]
            }) // 节假日详细禁用时间
          })
        } else {
          holidayLimitIds = []
          holidayTimeList = [] // [{ value: ['00:00', '23:59'] }]
        }
      }
      // 周 回显的id
      let weekLimitChecked = []
      // 周详细禁用时间
      let weekTimeList = [] // [{ value: ['00:00', '23:59'] }]
      if (this.rowData.weekLimit) {
        weekTimeList = []
        let wArr = []
        if (typeof this.rowData.weekLimit !== 'string') {
          wArr = this.rowData.weekLimit
        } else {
          if (typeof JSON.parse(this.rowData.weekLimit) != 'string') {
            wArr = JSON.parse(this.rowData.weekLimit)
          } else {
            wArr = JSON.parse(JSON.parse(this.rowData.weekLimit))
          }
        }
        if (wArr.length != 0) {
          wArr.forEach(e => {
            // console.log(e)
            if (weekLimitChecked.indexOf(e.weekCode) == -1) {
              weekLimitChecked.push(e.weekCode) // 周 回显的id
            }
          })
          wArr[0].timeList.forEach(itm => {
            weekTimeList.push({
              value: [itm.beginTime, itm.endTime]
            }) // 节假日详细禁用时间
          })
        } else {
          weekLimitChecked = []
          weekTimeList = [] // [{ value: ['00:00', '23:59'] }]
        }
      }

      // 日期
      let dayDateList = [{ value: '' }]
      // 周详细禁用时间
      let dayTimeList = [] // [{ value: ['00:00', '23:59'] }]
      if (this.rowData.dayLimit) {
        dayDateList = []
        dayTimeList = []
        let dArr = []
        if (typeof this.rowData.dayLimit !== 'string') {
          dArr = this.rowData.dayLimit
        } else {
          if (typeof JSON.parse(this.rowData.dayLimit) != 'string') {
            dArr = JSON.parse(this.rowData.dayLimit)
          } else {
            dArr = JSON.parse(JSON.parse(this.rowData.dayLimit))
          }
        }
        if (dArr.length != 0) {
          dArr.forEach(e => {
            // console.log(e)
            // if(weekLimitChecked.indexOf(e.weekCode)==-1) {
            //   weekLimitChecked.push(e.weekCode) // 周 回显的id
            // }
            dayDateList.push({
              value: [e.beginTime, e.endTime]
            }) // 周详细禁用时间
            // e.timeList.forEach(itm => {
            //   dayTimeList.push({
            //     value: [itm.beginTime, itm.endTime]
            //   }) // 周详细禁用时间
            // })
          })
          dArr[0].timeList.forEach(itm => {
            dayTimeList.push({
              value: [itm.beginTime, itm.endTime]
            }) // 节假日详细禁用时间
          })
        } else {
          dayDateList = [{ value: '' }]
          dayTimeList = [] // [{ value: ['00:00', '23:59'] }]
        }
      }

      this.holidayLimit = this.rowData.holidayLimitIds ? this.rowData.holidayLimitIds : holidayLimitIds
      this.holidayTimeList = this.rowData.holidayTimeListDatas ? this.rowData.holidayTimeListDatas : holidayTimeList
      this.weekTimeList = this.rowData.weekTimeListDatas ? this.rowData.weekTimeListDatas : weekTimeList
      // this.methodArray1 = this.rowData.methodArray1Datas?this.rowData.methodArray1Datas:[]
      this.weekLimitChecked = this.rowData.weekLimitCheckedDatas ? this.rowData.weekLimitCheckedDatas : weekLimitChecked
      this.dayDateList = this.rowData.dayDateListDatas ? this.rowData.dayDateListDatas : dayDateList
      this.dayTimeList = this.rowData.dayTimeListDatas ? this.rowData.dayTimeListDatas : dayTimeList
      // 复选框
      // this.rowData.isHolidayLimit = this.rowData.isHolidayLimit == 0 ? false : this.rowData.isHolidayLimit == 1 ? true : this.rowData.isHolidayLimit
      // this.rowData.isWeekLimit = this.rowData.isWeekLimit == 0 ? false : this.rowData.isWeekLimit == 1 ? true : this.rowData.isWeekLimit
      // this.rowData.isDayLimit = this.rowData.isDayLimit == 0 ? false : this.rowData.isDayLimit == 1 ? true : this.rowData.isDayLimit
      // 根据每条的返回信息渲染弹窗页面内容 this.rowData
      // this.holidayTimeList =  [{
      //   value: ['06:50:00', '00:00:00']
      // }]
      // this.$nextTick(() => {
      //   that.$refs.forms.clearValidate()
      //   that.$refs.forms.resetFields()
      // })
      // console.log(this.rowData)
    },
    // 获取
    PopupDialogClose() {
      this.$emit('update:PopupDialogFlag', false)
      // 取消的时候需要完善细节
      // this.rowData.holidayListDatas = this.holidayList
      // this.rowData.holidayLimitIds = this.holidayLimit
      // this.rowData.holidayTimeListDatas = this.holidayTimeList
      // this.rowData.weekTimeListDatas = this.weekTimeList
      // // this.methodArray1 = this.rowData.methodArray1Datas?this.rowData.methodArray1Datas:[]
      // this.rowData.weekLimitCheckedDatas = this.weekLimitChecked
      // this.rowData.dayTimeListDatas = this.dayTimeList
      // this.rowData.dayDateListDatas = this.dayDateList
      // this.rowData.isHolidayLimit = this.rowData.isHolidayLimit==0?false:this.rowData.isHolidayLimit==1?true:this.rowData.isHolidayLimit
      // this.rowData.isWeekLimit = this.rowData.isWeekLimit==0?false:this.rowData.isWeekLimit==1?true:this.rowData.isWeekLimit
      // this.rowData.isDayLimit = this.rowData.isDayLimit==0?false:this.rowData.isDayLimit==1?true:this.rowData.isDayLimit

      // this.holidayLimit = []
      // this.holidayList = []
      // this.weekLimitChecked = []
      // this.dayTimeList = []
      // this.dayDateList = [{value: ''}]
      // this.weekTimeList = [{value: ['00:00', '23:59']}]
      // this.holidayTimeList = [{value: ['00:00', '23:59']}]
    },
    toSubmit() {
      const that = this
      this.rowData.isUpdateLimit = 1
      // 为了回显处理的数据 取消可能还需要完善
      this.rowData.holidayListDatas = this.holidayList
      this.rowData.holidayLimitIds = this.holidayLimit
      this.rowData.holidayTimeListDatas = this.holidayTimeList
      this.rowData.weekTimeListDatas = this.weekTimeList
      // this.methodArray1 = this.rowData.methodArray1Datas?this.rowData.methodArray1Datas:[]
      this.rowData.weekLimitCheckedDatas = this.weekLimitChecked
      this.rowData.dayTimeListDatas = this.dayTimeList
      this.rowData.dayDateListDatas = this.dayDateList
      // 改变当前数据的内容
      this.$message.success('保存成功！')
      this.PopupDialogClose()
      this.$emit('close')
      // isLimitChanged
      // this.rowData.isWorkUpdateLimit = 1
      // console.log(this.rowData.isDayLimit==false?0:this.rowData.isDayLimit==true?1:this.rowData.isDayLimit)
      // this.rowData.isHolidayLimit = this.rowData.isHolidayLimit == false ? 0 : this.rowData.isHolidayLimit == true ? 1 : this.rowData.isHolidayLimit
      // this.rowData.isWeekLimit = this.rowData.isWeekLimit == false ? 0 : this.rowData.isWeekLimit == true ? 1 : this.rowData.isWeekLimit
      // this.rowData.isDayLimit = this.rowData.isDayLimit == false ? 0 : this.rowData.isDayLimit == true ? 1 : this.rowData.isDayLimit
      // console.log(this.rowData)
    },
    // 增加节假日限制条数
    addHolidayLimit() {
      this.holidayTimeList.push({
        value: ['00:00', '23:59']
      })
    },
    // 增加周限制条数
    addWeekLimit() {
      this.weekTimeList.push({
        value: ['00:00', '23:59']
      })
    },
    // 增加日限制条数-日期
    addDayDateLimit() {
      this.dayDateList.push({
        value: ''
      })
    },
    // 增加日限制条数-日期-时间
    addDayLimit() {
      this.dayTimeList.push({
        // value: ''
        value: ['00:00', '23:59']
      })
    },
    // 确认
    DetermineClick() {
      // this.rowData.holidayLimit = this.holidayLimit this.holidayList 对比holiday，把dateList拼接在一起
      // console.log(this.holidayLimit)
      // 节假日禁用
      const temp = [] // 选择的节假日日期数组
      // let holidayIds = []
      // 节假日 选择的节假日id集合this.holidayLimit
      this.holidayList.forEach(ele => {
        this.holidayLimit.forEach(e => {
          if (ele.holiday == e) {
            // console.log(ele)
            // temp = temp.concat(ele.dateList)
            ele.dateList.forEach(itm => {
              // console.log(itm)
              temp.push({
                holiday: ele.holiday,
                dateName: itm
              })
            })
            // holidayId.push({
            //   hId: ele.holiday,
            //   dateList: ele.dateList
            // })
            // holidayId = holidayId.concat(ele.holiday)
          }
        })
      })
      // 节假日数据处理，传值
      // console.log(temp)
      const holidayTime = []
      this.holidayTimeList.forEach(e => {
        // console.log(e.value)
        if(e.value == null) {
          this.$message.error("请选择详细的节假日禁用时间")
          return;

        }
        holidayTime.push({
          beginTime: e.value[0],
          endTime: e.value[1]
        })
      })
      const holidayArr = []
      // console.log(this.holidayTimeList)
      temp.forEach(item => {
        // console.log(temp,"temp")
        holidayArr.push({
          holiday: item.holiday,
          dateName: item.dateName,
          timeList: holidayTime,
          sort: 1
        })
        // console.log(item)
      })
      // console.log(temp)
      // 处理好的holiday_limit 如果节假日禁用启用==1在传输holidayArr
      // console.log(holidayArr)
      // 开启节假日禁用，才传值
      if (this.rowData.isHolidayLimit == 1) {
        // 给节假日限制赋值
        this.rowData.holidayLimit = holidayArr
      } else {
        this.rowData.holidayLimit = []
      }

      // 周禁用 this.rowData.weekLimit
      const weekLimitArr = []
      const weekTime = []
      // 时间格式转换
      this.weekTimeList.forEach(e => {
        if(e.value == null) {
          this.$message.error("请选择详细的周禁用时间")
          return;
        }
        weekTime.push({
          beginTime: e.value[0],
          endTime: e.value[1]
        })
      })
      let isweek=1;
      this.methodArray1.forEach(ele => {
        this.weekLimitChecked.forEach(e => {
          if (ele.weekCode == e) {
            // console.log(ele)
            weekLimitArr.push({
              weekCode: ele.weekCode,
              weekName: ele.weekName,
              timeList: weekTime,
              sort: 1
            })
            isweek=0;
          }
        })
      })
      // 开启周禁用才传值
      if (this.rowData.isWeekLimit == 1) {
        // 给节假日限制赋值
        if(isweek==1){
          this.$message.error("请选择指定周控制")
          return;
        }
        this.rowData.weekLimit = weekLimitArr
      } else {
        this.rowData.weekLimit = []
      }

      // 日禁用
      // console.log(this.rowData.weekLimit)
      const dayTime = []
      const dayDateArr = []
      // 时间格式转换
      this.dayTimeList.forEach(e => {
        if(e.value == null) {
          this.$message.error("请选择详细的日禁用时间")
        }
        dayTime.push({
          beginTime: e.value[0],
          endTime: e.value[1]
        })
      })

      this.dayDateList.forEach(ele => {
        // console.log(ele)
        if (ele.value && ele.value != '') {
          dayDateArr.push({
            beginTime: ele.value[0],
            endTime: ele.value[1],
            timeList: dayTime,
            sort: 1
          })
        }
        // this.weekLimitChecked.forEach(e => {
        // if(ele.weekCode == e) {
        //   // console.log(ele)
        // weekLimitArr.push({
        //   weekCode: ele.weekCode,
        //   weekName: ele.weekName,
        //   timeList: weekTime,
        //   sort: 1
        // })
        // }
        // })
      })

      // 开启日禁用才传值
      if (this.rowData.isDayLimit == 1) {
        // 给节假日限制赋值
        this.rowData.dayLimit = dayDateArr
      } else {
        this.rowData.dayLimit = []
      }

      // this.rowData.isHolidayLimit = this.rowData.isHolidayLimit == false ? 0 : this.rowData.isHolidayLimit == true ? 1 : this.rowData.isHolidayLimit
      // this.rowData.isWeekLimit = this.rowData.isWeekLimit == false ? 0 : this.rowData.isWeekLimit == true ? 1 : this.rowData.isWeekLimit
      // this.rowData.isDayLimit = this.rowData.isDayLimit == false ? 0 : this.rowData.isDayLimit == true ? 1 : this.rowData.isDayLimit
      // console.log(dayDateArr)
      // this.dayDateList

      // console.log(this.weekTimeList)
      // console.log(this.rowData)
      // this.rowData.isLimitChanged = 1
      // 点击提交记得把变更的状态改了 this.rowData.isLimitChanged = 1

      // console.log(this.rowData.isHolidayLimit==false?0:this.rowData.isHolidayLimit==true?1:this.rowData.isHolidayLimit)
      this.toSubmit()

      // 拿this.rowData对比tableData 替换tableData

      //  this.$refs.forms.validate((valid) => {
      //   if (!valid) {
      //     this.$notify({
      //       title: '警告',
      //       message: '请根据页面提示完善信息',
      //       type: 'warning'
      //     })
      //     // this.activeName="first"
      //     return false
      //   }
      // this.toSubmit()
      // })
    },
    handleClick() {},
    onEditorBlur(editor) {}, // 失去焦点事件
    onEditorFocus(v) {}, // 获得焦点事件
    onEditorChange(v) {
      this.currentLength = v.text.length - 1
      this.$emit('input', this.content)
    } // 内容改变事件
  }
}
</script>
  <style>

  </style>
