<!-- 券详情 -->
<template>
    <!-- <w-dialog v-if="dialogVisible" title="改使用限制" closeFree width="1000px" v-model="dialogVisible" okText="提 交" @cancel="PopupDialogClose" @ok="DetermineClick"> -->
    <el-dialog
        title="券详情"
        custom-class="dialogs"
        :close-on-click-modal="false"
        :visible.sync="dialogBrandVisible"
        width="700px"
        append-to-body
        @close="dialogBrandVisible=false"
        >
        <div style="margin-top: -15px;padding:0 30px">
            <el-descriptions :column="1">
              <el-descriptions-item label="券名称">{{brandInfo.brandName}}</el-descriptions-item>
              <el-descriptions-item label="所属券类">{{brandInfo.categoryName}}</el-descriptions-item>
              <el-descriptions-item label="券类型">
                <el-tag size="small">{{brandInfo.brandType==1?"空白券":brandInfo.brandType==2?"单体券":"组套券"}}</el-tag>
              </el-descriptions-item>
              <el-descriptions-item label="券用途" v-if="brandInfo.brandType!=1">
                <el-tag size="small">{{brandInfo.useType==1?"销售":brandInfo.useType==2?"免费":"工具"}}</el-tag>
              </el-descriptions-item>
              <el-descriptions-item label="是否通用" v-if="brandInfo.brandType==2">
                <el-tag size="small">{{brandInfo.isCommon==0?"否":"是"}}</el-tag></el-descriptions-item>
              <el-descriptions-item label="礼券内容" v-if="brandInfo.brandType==2">
                <div style="white-space: pre-wrap;" v-html="brandInfo.tktDetails"></div>
              </el-descriptions-item>
              <el-descriptions-item label="使用须知" v-if="brandInfo.brandType==2">
                <div style="white-space: pre-wrap;" v-html="brandInfo.tktNotice"></div>
              </el-descriptions-item>
          </el-descriptions>
        </div>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogBrandVisible=false">取消</el-button>
      </div>
    </el-dialog>
    <!-- </w-dialog> -->
  </template>
  <script>
  import {getBrandInfo} from '@/api/process.js'
  export default {
    props: {

    },
    watch: {

    },
    data() {
      return {
        dialogBrandVisible: false,
        brandInfo:{}
      };
    },
    computed: {},
    methods: {
      opening(brandId){
        this.dialogBrandVisible=true
        getBrandInfo(brandId).then(response => {
              if(response.code==200){
                this.brandInfo=response.data
              }
        })
      },


    },
  };
  </script>
  <style>

  </style>
